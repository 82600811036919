<template>
  <div>
    <div class="mb-button" :style="{width: width, height: height, lineHeight: height, fontSize: fontSize}"
      :class="{'inactive': disabled}" @click="handleClick()">
      <img class="button-img" />
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MbButtom',
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    width: {
      default () {
        return '80px'
      }
    },
    height: {
      default () {
        return '40px'
      }
    },
    fontSize: {
      default () {
        return '18px'
      }
    }
  },
  data () {
    return {
    }
  },
  methods: {
    handleClick () {
      // 不能点击
      if (this.disabled) return
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.mb-button {
  // box-sizing: border-box;
  padding: 8px 22px 8px 22px;
  // background: #F1DBC3;
  display: inline-block;
  text-align: center;
  font-size: 18px;
  color: #5f493d;
  position: relative;
  z-index: 1;
  .button-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .button-view {
    position: relative;
  }
  &.inactive {
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }
}
</style>
