<template>
<!-- 注入LP -->
  <div class="unstaking-wrap">
    <van-tabs type="card" background="#DDB889" color="#B36D25" :border="false">
      <van-tab title="获取LP">
        <div class="set-flow">注入流动性以接收LP代币</div>
        <div class="set-lp-flow-tip">注入流动性</div>
        <div class="staking-num-box">
          <div class="staking-num-title">{{common.Input}}</div>
          <div class="balance-of">{{common.Balance}}：{{usdtBalance}}</div>
        </div>
        <div class="staking-input-box">
          <input class="input-border" placeholder="0.0" v-model="inputValue"/>
          <MbButtom @click="handleToMax(1, usdtBalance)" width="30px" height="23px">MAX</MbButtom>
          <img class="bitcoin"/>
          <div class="lp">USDT</div>
        </div>
        <div class="staking-num-box">
          <div class="staking-num-title">{{common.Input}}</div>
          <div class="balance-of">{{common.Balance}}：{{gepBalance}}</div>
        </div>
        <div class="staking-input-box">
          <input class="input-border" placeholder="0.0" v-model="outputValue"/>
          <MbButtom @click="handleToMax(2, gepBalance)" width="30px" height="23px">MAX</MbButtom>
          <img  class="bitcoin"/>
          <div class="lp">GEP</div>
        </div>
        <div class="lp-usdt-box" v-if="false">
          <div class="lp-value-title">{{common.Price}}</div>
          <div class="lp-value">678991.2345563 USDT per GEP<br>678991.2345563 GEP per USDT</div>
        </div>
       <div class="lp-usdt-box" v-if="false">
          <div class="lp-value-title">流动池中的份额</div>
          <div class="lp-value">0.01%</div>
        </div>
        <div class="btn-wrap">
          <MbButtom width="100px"  @click="approveContract"> Enable</MbButtom>
          <MbButtom width="100px"  :disabled="approveAdd" @click="addLiquidity">Supply</MbButtom>
        </div>
      </van-tab>
      <van-tab title="取回代币">
        <div class="lp-box">
          <div class="lp-name">USDT-GEP</div>
          <div class="lp-num">{{lpbalance}}</div>
        </div>
         <div class="btn-wrap">
          <MbButtom width="100px"  @click="removeApprove"> Enable</MbButtom>
          <MbButtom width="100px"  @click="removeLiquidity">Supply</MbButtom>
        </div>
      </van-tab>
    </van-tabs>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import MbButtom from '@/components/mb-button/MbButtom.vue'
import pancekeFactory from '../../../utils/factory' // factory
import USDTGEPABI from '../../../static/abi/lpToken' // usdt-gep的abi文件
import config from '../../../config/app.config'
const contractsInfo = config.contractsInfo
const Web3 = require('web3')
const web3 = new Web3(window.ethereum)

export default {
  components: { MbButtom },
  computed: {
    ...mapState(['myAcount']),
    common () {
      return this.$t('common')
    }
  },
  data () {
    return {
      tokenGep: contractsInfo.tokenGEPContract,
      tokenUsdt: contractsInfo.tokenUSDTContract,
      usdtBalance: 0,
      gepBalance: 0,
      inputValue: '', // usdt币
      outputValue: '', // gep币
      approveAdd: true, // 添加是否授权
      approveRomove: true, // 移除是否授权
      routerContract: {
        chainId: 97,
        address: contractsInfo.routerContract.address,
        abi: contractsInfo.routerContract.address
      },
      lpbalance: 0, // lp剩
      liquidity: '', // lp取量
      lpAddress: '', // lp地址
      tableTitleList: [
        { title: '数量', value: 0 },
        { title: '质押时长', value: 1 },
        { title: '时间', value: 2 },
        { title: '状态', value: 3 }
      ],
      valueList: [
        {
          count: '10055.577',
          stakingTime: '7天',
          time: '2021/04/5 12:33',
          status: 1
        }, {
          count: '104555.577',
          stakingTime: '7天',
          time: '2021/04/5 12:33',
          status: 2
        },
        {
          count: '103455.577',
          stakingTime: '7天',
          time: '2021/04/5 12:33',
          status: 1
        },
        {
          count: '10055.577',
          stakingTime: '7天',
          time: '2021/04/5 12:33',
          status: 2
        }
      ]
    }
  },
  methods: {
    init () {
      this.getBalance()
      this.getLp()
    },
    // 获取剩
    async getBalance () {
      const usdtResp = await this.$web3.balanceOf(this.tokenUsdt)
      if (usdtResp.success) {
        this.usdtBalance = this.$gbUtils.formatBalance(usdtResp.result) || 0
      }
      const gepResp = await this.$web3.balanceOf(this.tokenGep)
      if (gepResp.success) {
        this.gepBalance = this.$gbUtils.formatBalance(gepResp.result) || 0
      }
    },
    // 获取lp信息
    async getLp () {
      //  factory: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',  //PancakeSwap V2 factory
      // router: '0x10ED43C718714eb63d5aA57B78B54704E256024E', //PancakeSwap V2 router
      console.log(pancekeFactory)
      const resp = await pancekeFactory.getPair(this.tokenUsdt.address, this.tokenGep.address)
      if (!resp.success) return this.$toast(resp.success)
      const lpAddress = resp.result
      console.log('lpaddress:', lpAddress)
      this.lpAddress = lpAddress
      const lpObj = {
        abi: USDTGEPABI,
        address: lpAddress
      }
      this.lpObj = lpObj
      const lpbalanceResp = await this.$web3.balanceOf(lpObj)
      this.lpbalance = this.$gbUtils.formatBalance(lpbalanceResp.result)
      // console.log(contractRouter.methods.getAmountsIn())
      // console.log(await this.$web3.getBalance())
    },
    // max
    handleToMax (type, value) {
      if (type === 1) {
        this.inputValue = value
      } else {
        this.outputValue = value
      }
    },
    handleToBackPage () {
      this.$router.back(1)
    },
    // 添加流动性授权
    async approveContract () {
      if (!this.inputValue || !this.outputValue) { return this.$toast('please input value') }
      const inputToken = new web3.eth.Contract(this.tokenUsdt.abi, this.tokenUsdt.address) // 获取tokenA的token合约
      this.$toast.loading({ duration: 0 })
      const resp = await this.$web3.approveContract(inputToken, this.inputValue) // 授权tokenA
      this.$toast.clear()
      if (!resp.success) return this.$toast(resp.message)
      console.log('签名回调1', resp)
      this.$toast.loading({ duration: 0 })
      const outToken = new web3.eth.Contract(this.tokenGep.abi, this.tokenGep.address) // 获取tolenB的token合约
      const resp1 = await this.$web3.approveContract(outToken, this.outputValue) // 授权tokenB
      this.$toast.clear()
      console.log('签名回调2', resp1)
      if (!resp1.success) return this.$toast(resp1.message)
      this.approveAdd = false
    },
    // 添加流动性
    async addLiquidity () {
      this.$toast.loading({ duration: 0 })
      if (!this.inputValue || !this.outputValue) { return this.$toast('please input value') }
      const addResp = await this.$web3.addLiquidity(this.inputValue, this.outputValue)
      console.log('添加流动性回调', addResp)
      this.$toast.clear()
      if (!addResp.success) return this.$toast(addResp.message)
      this.init()
    },
    // 移除流动性授权
    async removeApprove () {
      this.$toast.loading({ duration: 0 })
      // const resp = await await this.$web3.removeLiquidityWithPermit(this.lpbalance)
      console.log(this.lpObj)
      const lpToken = new web3.eth.Contract(this.lpObj.abi, this.lpObj.address) // 获取lptolen的token合约
      this.$toast.loading({ duration: 0 })
      console.log(this.lpbalance)
      const resp = await this.$web3.approveContract(lpToken, this.lpbalance) // 授权lptoken
      this.$toast.clear()
      console.log('移除流动性授权', resp)
      if (!resp.success) return this.$toast(resp.message)
      this.approveRomove = false
    },
    // 移除流动性
    async removeLiquidity () {
      this.$toast.loading({ duration: 0 })
      const resp = await this.$web3.removeLiquidity(this.lpbalance)
      this.$toast.clear()
      console.log('移除授权', resp)
      if (!resp.success) return this.$toast(resp.message)
      this.init()
    }
  },
  mounted () {
    // const minDestinationAmount = new this.$BigNumber(1).multipliedBy(99).dividedBy(100)
    // console.log(minDestinationAmount)
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.unstaking-wrap{
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  .get-lp-title{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    .title{
      font-size: 20px;
      font-weight: 500;
      color: #5f493d;
      text-align: center;
      margin-right: 30px;
    }
  }
  .set-flow{
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .set-lp-flow-tip{
    font-size: 14px;
    font-weight: 500;
    color: #000;
    margin-bottom: 10px;
  }
 .staking-num-box{
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 10px;
    .staking-num-title{
        font-size: 13px;
      font-weight: 400;
      color: #FFFFAC;
      -webkit-text-stroke: 1px #B36D25;
    }
    .balance-of{
      font-size: 13px;
      font-weight: 400;
      color: #FFFFAC;
      -webkit-text-stroke: 1px #B36D25;
    }
  }
  .staking-input-box{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 20px;
    .input-border{
      background-size: 100% 100%;
      display: flex;
      justify-content: flex-start;
      align-content: center;
      padding: 10px;
      width: 110px;
      border: none;
      -webkit-text-stroke: 1px #B36D25;
      margin-right: 6px;
    }
    .bitcoin{
      display: block;
      widows: 20px;
      height: 20px;
      margin-right: 6px;
      margin-left: 6px;
    }
    .lp{
      font-size: 14px;
      font-weight: 400;
      -webkit-text-stroke: 1px #B36D25;
    }
  }
  .lp-usdt-box{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    .lp-value-title{
      font-size: 14px;
      font-weight: 500;
      -webkit-text-stroke: 1px #B36D25;
      margin-right: 20px;
    }
    .lp-value{
      font-size: 14px;
      font-weight: 400;
      -webkit-text-stroke: 1px #B36D25;
    }
  }
  .btn-wrap{
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin-top: 20px;
  }
}

.lp-box {
  margin: 30px auto;
  width: 300px;
  padding: 20px;
  box-sizing: border-box;
  background-size: 100% 100%;
  .lp-name{
    font-size: 20px;
    font-weight: bold;
    color: #B36D25;
  }
  .lp-num {
    font-size: 14px;
    font-weight: 400;
    color: #FFFFAC;
    -webkit-text-stroke: 1px #B36D25;
    margin-top: 5px;
  }
}

</style>
